<template>
  <div>
    <v-container style="max-width: 1200px; min-height: 500px">
      <v-card v-if="success" color="green" dark>
        <v-card-title>
          <v-icon left>mdi-check</v-icon> {{ $capitalize($tc("model.integration_service_sent_title")) }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="toggleSuccess()" text>{{ $capitalize($tc("model.integration_submit_action_btn")) }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-row v-else>
        <v-col cols="12" md="7">
          <v-card
            :disabled="loading"
            flat
            color="#f1f1f1"
            :loading="loading_files"
          >
            <v-card-title class="grey--text">
              {{ $capitalize($tc("model.integration_attach_docs_title")) }}
              <v-spacer></v-spacer>
              <v-btn @click="getFiles()" :loading="loading_files" icon>
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-alert
              v-if="actionRequiresAttachment()"
              type="error"
              outlined
              text
            >
              {{ $capitalize($tc("model.integration_choose_attachment_alert")) }}
            </v-alert>
            <v-list color="#f1f1f1">
              <div v-for="(file, index) in orderedFiles" :key="file.id">
                <v-card class="mb-2" @click="handlePreviewFile(file)">
                  <v-list-item :disabled="isAttachmentDisabled(file)">
                    <v-list-item-avatar>
                      <v-avatar color="info">
                        <v-icon size="19" dark>mdi-file</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> 
                        {{ file.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle :class="{
                        'red--text': file.reached_size_limit
                      }">
                        {{ file.size }} KB
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ $capitalize($tc("model.integration_created_on_title_list")) }} {{ file.created_on }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-chip x-small label outlined v-if="requireLastVersionFinalReport(file) && file.external_reference == null" color="red" class="mr-1">
                          Obrigatório
                        </v-chip>
                        <v-chip x-small label v-if="file.category">
                          {{ file.category.description }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        :value="existAttachment(file)"
                        @click.stop="handleToggleFile(file)"
                        :disabled="isAttachmentDisabled(file)"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>

                <v-divider v-if="index + 1 < files.length"></v-divider>
              </div>
            </v-list>
            <v-card-text v-if="files.length === 0 && !loading_files">
              <div class="text-center">{{ $capitalize($tc("model.integration_no_attachments_found_alert")) }}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <ValidationObserver ref="form">
            <v-form @submit.prevent="handleSubmitForm">
              <v-card elevation="10">
                <v-card-title class="grey--text"> {{ $capitalize($tc("model.integration_action_title")) }} </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <ValidationProvider
                        name="code"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          :items="actions"
                          return-object
                          item-text="name"
                          :label="
                            $capitalize($tc('model.integration_actions_field'))
                          "
                          v-model="form.action"
                          :error-messages="errors"
                          name="code"
                          :disabled="loading"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <ValidationProvider
                    name="action"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-radio-group
                      name="action"
                      v-model="form.motive"
                      :label="
                        $capitalize($tc('model.integration_reason_label'))
                      "
                      v-if="form.action"
                      :error-messages="errors"
                      :disabled="loading"
                    >
                      <v-radio
                        v-for="motive in form.action.motives"
                        :key="motive.external_code"
                        :label="motive.name"
                        :value="motive"
                      ></v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                  <ValidationProvider
                    name="justification"
                    :rules="is_justification_required ? 'required|': '' + 'max:255'"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      :label="$capitalize($tc('model.integration_justification_label'))"
                      v-model="form.justification"
                      outlined
                      :error-messages="errors"
                      :counter="255"
                      name="justification"
                      :disabled="loading"
                    ></v-textarea>
                  </ValidationProvider>
                  <div class="my-3">
                    <v-icon left>mdi-paperclip</v-icon>
                    {{ attachments.length }} {{ $capitalize($tc("model.integration_selected_attachment_alert")) }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    type="submit"
                    block
                    large
                    color="primary"
                    elevation="0"
                    :loading="loading"
                    :disabled="loading"
                  >
                    <v-icon class="mr-3" left> mdi-cloud-upload </v-icon> 
                    {{ $capitalize($tc("model.integration_send_button")) }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="file_preview" max-width="700px" overlay-opacity=".9">
      <v-card dark>
        <v-card-title>
          {{ file.name }}
          <v-spacer />
          <v-btn @click="file_preview = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-img
          contain
          max-height="600"
          v-if="isFileImage(file)"
          :src="getFilePublicUrl(file)"
        ></v-img>
        <iframe
          v-else
          :src="getFilePublicUrl(file)"
          frameborder="0"
          width="100%"
          height="500px"
          style="background-color: white"
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { attachment_categories } from "@/services/attachment";

export default {
  data: function () {
    return {
      loading_files: false,
      files: [],
      form: {},
      file: {},
      file_preview: false,
      attachments: [],
      loading: false,
      success: false,
      actions: []
    };
  },
  methods: {
    actionRequiresAttachment() {
      if (!this.form.action) return false;
      return this.form.action.requires_attachment && this.attachments.length === 0;
    },
    async handleSubmitForm() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      if (this.actionRequiresAttachment()) return;

      this.loading = true;
      try {
        const payload = {
          ...this.form,
          action: this.form.action.external_code,
          motive: this.form.motive.external_code,
          attachments: this.attachments,
        };

        const response = await this.$http.post(
          `/leads/${this.$route.params["lead_number"]}/eps_notifications`,
          payload
        );

        this.toggleSuccess();
      } catch (error) {
        if (error?.response?.data?.message) {
          this.$store.commit("sendMessage", {
            text: error?.response?.data?.message,
            color: 'red'
          });
        }
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form = {};
      this.attachments = [];
      // this.$refs.form.reset();
    },
    toggleSuccess() {
      this.success = !this.success;
      this.resetForm();
    },
    handleToggleFile(file) {
      if (this.existAttachment(file)) {
        this.attachments = this.attachments.filter(
          (attachment) => attachment !== file.id
        );
      } else {
        this.attachments.push(file.id);
      }
    },
    existAttachment(file) {
      return !!this.attachments.find((attachment) => file.id === attachment);
    },
    handlePreviewFile(file) {
      this.file = file;
      this.file_preview = true;
    },
    getFilePublicUrl(file) {
      switch (file.extension) {
        case "xlsx":
        case "pptx":
        case "docx":
          return this.microsoft_preview(file.public_url);
        default:
          return file.public_url;
      }
    },
    microsoft_preview(public_url) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${public_url}`;
    },
    isFileImage(file) {
      const image_extensions = ["jpg", "jpeg", "png", "gif"];

      let is_image = false;

      image_extensions.forEach((extension) => {
        if (
          file.extension &&
          extension === file.extension.split(".").join("")
        ) {
          is_image = true;
        }
      });

      return is_image;
    },
    async getFiles(silent = false) {
      const lead_number = this.$route.params["lead_number"];

      this.loading_files = true;
      if (silent == false) {
        this.files = [];
      }
      const response = await this.$http.get(
        `/leads/${lead_number}/eps_notifications/attachments`
      );
      this.files = response.data;
      this.loading_files = false;
    },
    requireLastVersionFinalReport(attachment) {
      if (this.form.action?.canonical == 'eps_send_final_report' && attachment.category_id == attachment_categories.final_report) {
        return this.isLastVersionFinalReport(attachment);
      }

      return false;
    },
    isLastVersionFinalReport(attachment) {
      const sorted_attachments_desc = []
        .concat(this.files)
        .filter(item => item.category_id == attachment_categories.final_report)
        .sort((a, b) => 
          this.$moment(a.created_at).diff(this.$moment(b.created_at))
        )
        .reverse();

      const last_version = sorted_attachments_desc[0];
      return last_version && last_version?.id == attachment.id;
    },
    allowLastVersionFinalReport(attachment) {
      if (this.form.action?.canonical != 'eps_send_final_report' && attachment.category_id == attachment_categories.final_report) {
        return false;
      }

      if (this.form.action?.canonical == 'eps_send_final_report' && attachment.category_id == attachment_categories.final_report) {
        return this.isLastVersionFinalReport(attachment);
      }

      return true;
    },
    isAttachmentDisabled(attachment) {
      if(!this.allowLastVersionFinalReport(attachment)) {
        return true;
      }

      if(attachment.reached_size_limit) {
        return true;
      }

      return false;
    },
    loadActions() {
      if (this.lead_type) {
        const actions = this.$store.getters['eps/getActions']
          .map(action => {
            const external_action = action.external_actions[this.lead_type];
            
            if (external_action) {
              return {
                ...external_action,
                canonical:  action.canonical,
                name: action.name,
                disabled: !this.isActionValid(external_action)
              };
            }
          })
          .filter(action => {
            return action?.interactive;
          });

          const allowed_actions = actions.filter(action => !action.disabled);
          const disabled_actions = actions.filter(action => action.disabled);

          this.actions = allowed_actions;
          
          if (disabled_actions.length > 0) {
            this.actions.push({ divider: true });
            this.actions.push(...disabled_actions);
          }
      }
    },
    isActionValid(action) {
      return (action?.states || []).some(state => state.external_code == this.lead_state?.external_code);
    }
  },
  computed: {
    lead_type: function() {
      return this.$store.getters.getLead?.lead_type;
    },
    lead_state: function() {
      return this.$store.getters.getLead?.default_service?.external_status;
    },
    folders: function () {
      return [];
    },
    loadingActions() {
      return !this.$store.getters['eps/getActions'].length > 0;
    },
    is_justification_required() {
      if (!this.form.motive) return false;

      return this.form.motive.needs_justification;
    },
    orderedFiles() {
      const files = this.files;

      return files.sort((a,b) => {
        const isADisabled = this.isAttachmentDisabled(a);
        const isBDisabled = this.isAttachmentDisabled(b);

        if(isADisabled && !isBDisabled) {
          return 1;
        }

        if(!isADisabled && isBDisabled) {
          return -1;
        }

        return 0;
      });
    }
  },
  watch: {
    lead_type(value, oldValue) {
      if (value != null && oldValue == null && !this.loadingActions) {
        this.loadActions();
      }
    },
    loadingActions(value) {
      if (!value) this.loadActions();
    }
  },
  created() {
    this.loadActions();
    this.getFiles();
  },
};
</script>